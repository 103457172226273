<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2019-12-10 19:38:31
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-28 19:21:42
 -->
<template>
  <div class="mainbody">
    <div
      @click="tz_history"
      :style="{ backgroundColor: getStorage('theme') }"
      class="chistory_jhs"
    >
      历史计划书
    </div>
    <div class="top">
      <div class="top_search">
        <div class="top_search-left" @click="companyClcik">
          {{
            formlist.shortname
              ? formlist.shortname.length > 4
                ? formlist.shortname.substr(0, 4)
                : formlist.shortname
              : "保险公司"
          }}
          <md-icon class="icon" name="arrow-down"></md-icon>
        </div>
      </div>
      <!--  搜索框  -->
      <div class="searchbox clearfix">
        <div class="searchline clearfix">
          <button class="btnicon">
            <md-icon name="search" size="lg" color="#D6D6D6"></md-icon>
          </button>
          <input
            @click="Jump"
            type="text"
            placeholder="请输入产品名称"
            v-model="formlist.cpname"
            @keyup.13="search"
            readonly
            v-on:focus="cleardatafocus"
          />
          <button
            class="btnclear"
            @click="clearseadata"
            v-show="cleardatatrue && formlist.cpname"
          >
            <md-icon name="fail" size="lg" color="#666"></md-icon>
          </button>
        </div>
        <div class="searchTxt" @click="search">搜索</div>
      </div>
      <!-- <div></div> -->
    </div>
    <div class="list" v-if="!isNull">
      <md-scroll-view
        ref="scrollView"
        auto-reflow
        :scrolling-x="false"
        @end-reached="loadMorechange"
        :loading-text="
          listtotalPage < listpageNo || listtotalPage == listpageNo
            ? '没有要加载的数据啦...'
            : '正在加载中'
        "
      >
        <div
          class="product"
          v-for="(item, index) of reportList"
          :key="index"
          @click="makeout(item.baseid, item.cpcode)"
        >
          <div class="product-lf">
            <img :src="item.iconhttp" alt="" />
          </div>
          <div class="product-rg">
            <div class="product-rg-cen">
              <div class="product-rg-top">
                <p>{{ item.cpname }}</p>
              </div>
              <div class="product-rg-bom">
                <p>{{ item.prodesc }}</p>
              </div>
              <div class="product-img">
                <!-- -----------------防止穿透-------------------------- -->
                <div @click.prevent.stop="met(item)">签单必看</div>
                <!-- ------------------------------------------- -->
                <img :src="`${item.showhttp}`" alt="" />
              </div>
            </div>
            <md-icon class="product-rg-icon" name="arrow-right"></md-icon>
          </div>
        </div>
        <md-scroll-view-more slot="more" :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <md-result-page
      :img-url="require('@/assets/abd/image/null.png')"
      v-else
      subtext="要不然刷新试试？"
    />
    <!-- 保险公司赛选 -->
    <md-popup v-model="isSelectorShow" position="left" class="md-popupleft">
      <div class="md-examplepopup">
        <div class="search_bxgs">
          <md-input-item
            v-model="search_txt"
            ref="input11"
            placeholder="请输入保险公司"
            clearable
          >
            <md-icon name="search" slot="left"></md-icon>
          </md-input-item>
          <div @click="retrieval" class="search_text">搜索</div>
        </div>
        <div class="history">
          <div>搜索历史</div>
          <md-icon @click="del" name="delete"></md-icon>
        </div>
        <div class="t_history">
          <div
            v-for="(history, index) in compory_name"
            :key="index"
            @click="cli(history, index)"
            :style="{
              backgroundColor:
                ss_history == index && color_gj == true
                  ? getStorage('theme')
                  : '',
              color: ss_history == index && color_gj == true ? 'white' : '',
            }"
          >
            {{ history.shortname }}
          </div>
        </div>
        <div class="popupleft-title">保险公司</div>
        <div
          class="titlecontent"
          v-for="(item, index) in shortName"
          :key="index"
          v-if="item.data.length > 0"
        >
          <div>{{ item.tag }}</div>
          <div @click="change_color(item, index)">
            <div
              v-for="(ite, ind) in item.data"
              :key="ind"
              @click="change_compory_color(ite, ind)"
              :style="{
                backgroundColor:
                  gb_compory_color == ind && change_colorr == item.tag
                    ? getStorage('theme')
                    : '',
                color:
                  gb_compory_color == ind && change_colorr == item.tag
                    ? 'white'
                    : '',
              }"
            >
              <div>
                {{ ite.shortname }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-cz">
        <div class="bottom-chongz" @click="reset">
          <div class="chongzhi">重置</div>
        </div>
        <div
          class="bottom-qued"
          :style="{ backgroundColor: getStorage('theme') }"
          @click="onSelectorChoose"
        >
          <div class="queding">确定</div>
        </div>
      </div>
    </md-popup>
  </div>
</template>
<script>
import { Selector, Field, FieldItem, InputItem } from "mand-mobile";
import { productlist, getjhsin } from "@/api/jhs/productlist/index";
import { getStorage, setStorage, isAndroid } from "@/lib/util";
import loadMorechange from "@/mixins/loadMorechange";
import config from "@/config";
const { cvu } = config;
import store from "@/store";

export default {
  mixins: [loadMorechange],
  components: {
    [Selector.name]: Selector,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [InputItem.name]: InputItem,
  },
  data() {
    return {
      cleardatatrue: false,
      picurl: "",
      formlist: {
        baseid: "",
        cpname: "",
      },
      search_txt: "", //搜索框中的关键词
      ss_history: "", //点击改变搜索历史块的颜色
      personNum: 1,
      ossurl: "",
      isSelectorShow: false,
      selectorValue: "",
      shortName: [],
      colorprimary: "",
      loadflag: false,
      listFinished: false,
      listpageNo: 1,
      pageSize: 8,
      reportList: [],
      listtotal: 0,
      listtotalPage: 0,
      isNull: false,
      gb_compory_color: "", //点击改变公司块颜色
      compory_name: [], //搜索历史存至缓存
      change_colorr: "",
      short: [], //存至缓存便于makeout.vue使用
      color_gj: false, //搜索历史的背景颜色
      list: [],
    };
  },
  created() {
    // if (!getStorage("compory_name")) {
    //   setStorage("compory_name", []);
    // } else {
    //   this.compory_name = getStorage("compory_name", []);
    // }
    if (getStorage("compory_name") && getStorage("compory_name") != "[]") {
      this.compory_name = getStorage("compory_name", []);
    } else {
      setStorage("compory_name", []);
    }
    this.picurl = getStorage("msossurl", "");
    this.formlist.cpname = this.$route.query.name ? this.$route.query.name : "";
    this.getData();
    // 调用接口获取全部以及A~Z的保险公司
    getjhsin({
      comid: getStorage("u_s", "").comid,
      basename: this.search_txt,
      type: "JHS",
    }).then((res) => {
      this.shortName = res.data.data;
      this.shortName.forEach((item, index) => {
        item.data.forEach((it, ind) => {
          this.short.push(it);
        });
      });
      setStorage("jihuashu_shortName", this.short);
    });
  },
  mounted() {
    // 解决苹果手机返回空白页不刷新的问题
    window.addEventListener("pageshow", function (e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        location.reload();
      }
    });
  },
  methods: {
    // 跳转至历史计划书
    tz_history() {
      this.$router.push("/history_jhs");
    },
    // 点击搜索历史的关键字使输入框中回显并且使关键字改变背景色
    cli(history, index) {
      this.gb_compory_color = "in";
      this.ss_history = index;
      this.color_gj = true;
      this.formlist.baseid = history.baseid;
      this.formlist.shortname = history.shortname;
    },
    // 跳转至搜索页面
    Jump() {
      this.$router.push({
        path: "/search",
      });
    },
    // 点击签单必看按钮
    met(item) {
      this.$router.push(`/jhsinformation?prodcode=${item.cpcode}`);
    },
    // 删除搜索历史
    del() {
      setStorage("compory_name", []);
      this.$forceUpdate();
      this.compory_name = [];
    },
    // 改变公司块的颜色
    change_color(item, index) {
      this.change_colorr = item.tag;
    },
    // 点击弹框中的搜索搜索公司
    retrieval() {
      // 点击输入框旁的搜索按钮搜索关键字的所有保险公司;
      getjhsin({
        comid: getStorage("u_s", "").comid,
        basename: this.search_txt,
        type: "JHS",
      }).then((res) => {
        this.shortName = res.data.data;
      });
      // this.search_txt = "";
    },
    // 点击改变保险公司块的颜色
    change_compory_color(ite, ind) {
      this.ss_history = "in"; //点击公司块的按钮使搜索历史的保险公司块恢复颜色
      this.search_txt = ""; //点击公司块的按钮使搜索历史的输入框清空
      this.gb_compory_color = ind;
      this.formlist.baseid = ite.baseid;
      this.formlist.shortname = ite.shortname;
    },
    getData() {
      // store.state.app.isloading = true;
      let data = {
        page: this.listpageNo,
        size: this.pageSize,
        baseid: this.formlist.baseid,
        cpname: this.$route.query.name != "" ? this.$route.query.name : "",
      };
      productlist(data)
        .then((res) => {
          this.reportList =
            this.reportList == []
              ? res.data.data.records
              : this.reportList.concat(res.data.data.records);
          if (this.reportList.length > 0) {
            this.isNull = false;
          } else {
            this.isNull = true;
          }
          this.listtotal = res.data.data.total;
          this.listtotalPage = res.data.data.pages;
          if (this.listtotalPage <= this.listpageNo) {
            this.listFinished = true;
          } else {
            this.listFinished = false;
            this.$refs.scrollView.finishLoadMore();
          }
          this.loadflag = true;
        })
        .finally(() => {
          this.isSelectorShow = false;
          store.state.app.isloading = false;
        });
      this.$route.query.name = "";
    },
    cleardatafocus() {
      this.cleardatatrue = true;
    },
    cleardatablur() {
      this.cleardatatrue = false;
    },
    clearseadata() {
      this.formlist.cpname = "";
      this.listpageNo = 1;
      this.reportList = [];
      this.getData();
    },
    makeout(baseid, cpcode) {
      // 跳转至填写信息
      this.$router.push({
        path: "/makeout",
        query: {
          baseid: baseid,
          cpcode: cpcode,
          type: "CP",
          fromwhere: this.$route.query.fromwhere
            ? this.$route.query.fromwhere
            : "",
        },
      });
    },
    // 点击搜索
    search() {
      this.listFinished = false;
      this.loadflag = false;
      // this.$refs.scrollView.finishLoadMore();
      this.listpageNo = 1;
      this.reportList = [];
      this.getData(this);
    },
    cleardata() {
      this.formlist = {
        baseid: "",
      };
      this.listFinished = false;
      this.loadflag = false;
      // this.$refs.scrollView.finishLoadMore();
      this.listpageNo = 1;
      this.getData();
    },
    // 打开保险公司选择弹窗
    companyClcik() {
      this.$nextTick(() => {
        this.isSelectorShow = true;
      });
    },
    // 重置保险公司选择
    reset() {
      this.shortName.forEach((item, index) => {
        item.ischoose = false;
      });
      this.search_txt = ""; //输入框中的关键字置为空
      this.formlist.shortname = "";
      this.formlist.baseid = "";
      this.gb_compory_color = ""; //点击重置后使颜色变为原始
      this.change_colorr = ""; //点击重置后使颜色变为原始
      this.formlist.cpname = "";
      this.retrieval();
      this.ss_history = "in";
    },
    // 确定保险公司选择
    onSelectorChoose() {
      // let search_name = this.formlist.shortname.replace(/\s+/g, "");
    let search_name = this.formlist.shortname ? this.formlist.shortname.replace(/\s+/g, "")
        : "";
      if (search_name.length > 0) {
        let obj = {
          baseid: this.formlist.baseid,
          shortname: this.formlist.shortname,
        };
        this.compory_name.push(obj);
        //去重
        const res = new Map();
        this.compory_name = this.compory_name.filter(
          (a) => !res.has(a.shortname) && res.set(a.shortname, 1)
        );
        if (this.compory_name.length > 3) {
          this.compory_name.splice(0, 1);
        }
        setStorage("compory_name", this.compory_name);
      }

      this.listFinished = false;
      this.loadflag = false;
      // this.$refs.scrollView.finishLoadMore();
      this.reportList = [];
      this.listpageNo = 1;
      this.getData();
    },
  },
};
</script>
<style lang="stylus" scoped>
.icon {
  margin-left: 0.1rem;
}

.mainbody {
  background-color: #F7F6FB;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

// .jhs_img {
// height: 1.27rem;
// width: 1.2rem;
// position: fixed;
// z-index: 2;
// right: 0.3rem;
// object-fit: cover;
// bottom: 0.5rem;
// }
.product {
  height: 3.2rem;
  background: #fff;
  border-bottom: 20px solid #eee;
}

.product-lf {
  float: left;
  width: 30vw;
  height: 100%;
  text-align: center;
  position: relative;
}

.product-lf img {
  border-radius: 5px;
  position: absolute; // 相对定位
  width: 180px;
  height: 160px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; // 使其垂直居中
}

.product-rg {
  float: left;
  width: 70vw;
  height: 100%;
  position: relative;
  padding-right: 0.5rem;
}

.product-rg-cen {
  position: absolute; // 相对定位
  height: 160px;
  top: 0;
  left: -0.15rem;
  right: 0;
  bottom: 0.45rem;
  margin: auto; // 使其垂直居中
}

.product-rg-top {
  height: 50%;
  color: #393939;
}

.product-rg-top p {
  // font-weight: 550;  // 将字体加粗
  padding-top: 3%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.45rem;
  width: 5.2rem;
  margin-top: -0.03rem;
  color: black;
}

.product-rg-bom p {
  padding-top: 2%;
  font-size: 0.32rem;
  color: #808080;
  overflow: hidden;
  height: 0.6rem;
  width: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-rg-icon {
  margin-left: 95%;
  line-height: 3.2rem;
}

.list {
  height: 93%;
}

.top {
  // height 1.3rem
  height: 7vh;
  display: flex;
  // background-color #bdbdbd
}

.top_search {
  display: flex;
}

.top_search-left {
  display: flex;
  height: 100%;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  font-size: 0.38rem;
  font-weight: 400;
  font-family: Microsoft YaHei;
  color: rgba(102, 102, 102, 1);
}

.top_search-right {
  display: flex;
  width: 0.5rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.top_search-left img {
  margin-left: 0.2rem;
  width: 0.15rem;
  height: 0.15rem;
}

.searchbox {
  display: flex;
  // height: 0.87rem;
  height: 100%;
  width: 7.3rem;
  align-items: center;
  position: relative;

  .searchline {
    border-radius: 0.1rem;
    background: #F2F2F2;
    height: 0.87rem;
    width: 85%;
  }

  .btnicon {
    width: 16%;
    height: 0.87rem;
    border-radius: 0.3rem 0 0 0.3rem;
    background: none;
  }

  input {
    height: 0.87rem;
    border: none;
    background: none;
    font-size: 0.33rem;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #A5A5A5;
    position: absolute;
  }

  .btnclear {
    background: none;
    position: absolute;
    right: 1.4rem;
    line-height: 0.95rem;
  }

  .searchTxt {
    font-size: 0.36rem;
    color: #474747;
    margin-left: 0.25rem;
  }
}

.md-popupleft /deep/.md-slide-right {
  width: 82.6%;
  background-color: #ffffff;
  padding: 0 0.4rem 2rem 0.4rem;
}

.popupleft-title {
  margin-bottom: 0.61rem;
  font-size: 0.46rem;
  font-family: PingFang-SC-Bold;
  font-weight: bold;
  color: #3d3d3d;
  margin-top: 0.97rem;
}

.titlecontent {
  margin-bottom: 0.3rem;

  >div:first-child {
    margin-bottom: 0.2rem;
    font-size: 0.45rem;
  }

  >div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;

    >div {
      padding: 0.2rem 0.3rem;
      background-color: #f5f5f5;
      margin-right: 0.28rem;
      margin-bottom: 0.2rem;
      border-radius: 0.2rem;
    }
  }
}

.company-title {
  padding: 0.2rem 0.45rem;
  background-color: #f5f5f5;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #707070;
}

.companytitle {
  padding: 0.2rem 0.45rem;
  // background-color: #fb6822;
  margin-right: 0.3rem;
  margin-bottom: 0.25rem;
  font-size: 0.33rem;
  border-radius: 0.1rem;
  color: #fff;
}

.bottom-cz {
  height: 1.23rem;
  width: 82.6%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  bottom: 0;
  border-top: 0.01rem solid #f5f5f5;
}

.bottom-chongz {
  width: 50%;
  display: flex;
  align-items: center;
  background: white;
}

.bottom-qued {
  width: 50%;
  display: flex;
  align-items: center;
}

.chongzhi {
  margin: auto;
  color: #383838;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.queding {
  margin: auto;
  color: #ffffff;
  font-family: PingFang-SC-Medium;
  font-size: 0.43rem;
}

.contentscroll {
  calc(100vh - 2rem);
}

.search_bxgs {
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;

  .search_text {
    align-self: center;
  }

  /deep/ .md-field-item {
    background-color: #F2F2F2;
    width: 85%;
    border-radius: 0.1rem;
    padding-right: 0.3rem;
  }

  /deep/ .md-field-item-left {
    padding-left: 0.3rem;
  }
}

.history {
  display: flex;
  justify-content: space-between;
  margin-top: 0.61rem;

  div {
    font-size: 0.46rem;
    font-weight: bold;
  }

  /deep/ .md-icon.icon-font.md {
    font-size: 0.55rem;
    align-self: center;
    color: #AFADAD;
  }
}

// .all_compary {
// height: 80vh;
// overflow: auto;
// padding-bottom: 0.5rem;
// }
.t_history {
  display: flex;
  // justify-content: space-between;
  margin-top: 0.4rem;

  div {
    padding: 0.2rem 0.3rem;
    background-color: #F5F5F5;
    margin-right: 0.2rem;
  }
}

.product-img {
  display: flex;
  justify-content: space-between;
  margin-top: 0.15rem;

  div:first-child {
    background-color: #FFD7CD;
    color: #FF7A5C;
    align-self: center;
    padding: 0.15rem 0.35rem;
    border-radius: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.3rem;
  }

  img {
    align-self: center;
    margin-right: 0.6rem;
    width: 1.8rem;
    max-height: 0.9rem;
  }
}

.chistory_jhs {
  position: fixed;
  right: 0;
  bottom: 2rem;
  width: 2.6rem;
  text-align: right;
  padding: 0 0.2rem;
  height: 0.9rem;
  line-height: 0.9rem;
  color: #fff;
  font-weight: bold;
  font-size: 0.4rem;
  -webkit-border-bottom-left-radius: 6rem;
  -webkit-border-top-left-radius: 6rem;
  z-index: 99;
}
</style>
