/*
 * @Author: 张天鹏
 * @Date: 2019-12-11 11:39:19
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-28 16:11:59
 */
import axios from '@/lib/api.request'
// 产品列表
export const productlist = data => axios.post('/jhs/product/cplist', data)
// 险种列表
export const infolist = data => axios.post('/jhs/product/list', data)
// 计划书详情
export const getPdfInfo = data => axios.post('/jhs/product/getPdfInfo', data)
// 我的计划书列表
export const myJhsList = data => axios.post('/jhs/product/myJhsList', data)
// 我的计划书使用方案
export const useJhsInfo = data => axios.post('/jhs/product/useJhsInfo', data)
// 删除我的计划书中的记录
export const delJhsNo = data => axios.post('/jhs/product/delJhsNo', data)
// 搜索保险公司
export const getjhsin = data => axios.post('/jhs/product/getsupplier', data)
// 险种列表
export const getcpinfolist = data => axios.post('/jhs/product/getcpinfo', data)
// 生成计划书计算总保费
export const totlebaofei = data => axios.post('/jhs/product/jhscost', data)
// 生成计划书
export const prospectus = data => axios.post('/jhs/product/jhsresport', data)
// 供应商下拉
// export const selectShortName = params =>
//   axios.get('/saas/select/selectCPSupplieList', params)
// export const getjhsinsurance = params =>
//   axios.get('/jhs/product/getsupplier', { params })
// 计划书回显
export const showresport = data => axios.post('/jhs/product/showresport', data)
// 计划书病种
export const returnreport = data =>
  axios.post('/jhs/product/getdisgroup', data)
// 计划书资料
export const selectJhsCpInfo = params =>
  axios.get('/jhs/product/selectJhsCpInfo', {
    params
  })

/**  产品对比  */
// 产品对比列表
export const brinkinglist = data => axios.post('jhs/product/comporelist', data)
// 产品对比影响因子列表
export const getdbeff = data => axios.post('/jhs/product/getdbeff', data)

/** 投保信息 */
// 生成报告
export const comparergsum = data => axios.post('/jhs/product/comresport', data)
// 修改产品亮点
export const setMerit = data => axios.post('/jhs/product/setmerit', data)

// 产品列表
export const prodlist = data => axios.post('/jhs/product/prodlist', data)

// 产品已勾选列表
export const nextprodlist = data =>
  axios.post('/jhs/product/nextprodlist', data)
// 产品主副险列表
export const personclassclist = data =>
  axios.post('/jhs/product/personclassclist', data)
// 生成报告
export const generatereport = data =>
  axios.post('/jhs/product/generatereport', data)


// JAVA生成pdf
export const generatepdf = data =>
  axios.post('/jhs/product/generatepdf', data)
