var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainbody"},[_c('div',{staticClass:"chistory_jhs",style:({ backgroundColor: _vm.getStorage('theme') }),on:{"click":_vm.tz_history}},[_vm._v(" 历史计划书 ")]),_c('div',{staticClass:"top"},[_c('div',{staticClass:"top_search"},[_c('div',{staticClass:"top_search-left",on:{"click":_vm.companyClcik}},[_vm._v(" "+_vm._s(_vm.formlist.shortname ? _vm.formlist.shortname.length > 4 ? _vm.formlist.shortname.substr(0, 4) : _vm.formlist.shortname : "保险公司")+" "),_c('md-icon',{staticClass:"icon",attrs:{"name":"arrow-down"}})],1)]),_c('div',{staticClass:"searchbox clearfix"},[_c('div',{staticClass:"searchline clearfix"},[_c('button',{staticClass:"btnicon"},[_c('md-icon',{attrs:{"name":"search","size":"lg","color":"#D6D6D6"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formlist.cpname),expression:"formlist.cpname"}],attrs:{"type":"text","placeholder":"请输入产品名称","readonly":""},domProps:{"value":(_vm.formlist.cpname)},on:{"click":_vm.Jump,"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.search.apply(null, arguments)},"focus":_vm.cleardatafocus,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formlist, "cpname", $event.target.value)}}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.cleardatatrue && _vm.formlist.cpname),expression:"cleardatatrue && formlist.cpname"}],staticClass:"btnclear",on:{"click":_vm.clearseadata}},[_c('md-icon',{attrs:{"name":"fail","size":"lg","color":"#666"}})],1)]),_c('div',{staticClass:"searchTxt",on:{"click":_vm.search}},[_vm._v("搜索")])])]),(!_vm.isNull)?_c('div',{staticClass:"list"},[_c('md-scroll-view',{ref:"scrollView",attrs:{"auto-reflow":"","scrolling-x":false,"loading-text":_vm.listtotalPage < _vm.listpageNo || _vm.listtotalPage == _vm.listpageNo
          ? '没有要加载的数据啦...'
          : '正在加载中'},on:{"end-reached":_vm.loadMorechange}},[_vm._l((_vm.reportList),function(item,index){return _c('div',{key:index,staticClass:"product",on:{"click":function($event){return _vm.makeout(item.baseid, item.cpcode)}}},[_c('div',{staticClass:"product-lf"},[_c('img',{attrs:{"src":item.iconhttp,"alt":""}})]),_c('div',{staticClass:"product-rg"},[_c('div',{staticClass:"product-rg-cen"},[_c('div',{staticClass:"product-rg-top"},[_c('p',[_vm._v(_vm._s(item.cpname))])]),_c('div',{staticClass:"product-rg-bom"},[_c('p',[_vm._v(_vm._s(item.prodesc))])]),_c('div',{staticClass:"product-img"},[_c('div',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.met(item)}}},[_vm._v("签单必看")]),_c('img',{attrs:{"src":`${item.showhttp}`,"alt":""}})])]),_c('md-icon',{staticClass:"product-rg-icon",attrs:{"name":"arrow-right"}})],1)])}),_c('md-scroll-view-more',{attrs:{"slot":"more","is-finished":_vm.listFinished},slot:"more"})],2)],1):_c('md-result-page',{attrs:{"img-url":require('@/assets/abd/image/null.png'),"subtext":"要不然刷新试试？"}}),_c('md-popup',{staticClass:"md-popupleft",attrs:{"position":"left"},model:{value:(_vm.isSelectorShow),callback:function ($$v) {_vm.isSelectorShow=$$v},expression:"isSelectorShow"}},[_c('div',{staticClass:"md-examplepopup"},[_c('div',{staticClass:"search_bxgs"},[_c('md-input-item',{ref:"input11",attrs:{"placeholder":"请输入保险公司","clearable":""},model:{value:(_vm.search_txt),callback:function ($$v) {_vm.search_txt=$$v},expression:"search_txt"}},[_c('md-icon',{attrs:{"slot":"left","name":"search"},slot:"left"})],1),_c('div',{staticClass:"search_text",on:{"click":_vm.retrieval}},[_vm._v("搜索")])],1),_c('div',{staticClass:"history"},[_c('div',[_vm._v("搜索历史")]),_c('md-icon',{attrs:{"name":"delete"},on:{"click":_vm.del}})],1),_c('div',{staticClass:"t_history"},_vm._l((_vm.compory_name),function(history,index){return _c('div',{key:index,style:({
            backgroundColor:
              _vm.ss_history == index && _vm.color_gj == true
                ? _vm.getStorage('theme')
                : '',
            color: _vm.ss_history == index && _vm.color_gj == true ? 'white' : '',
          }),on:{"click":function($event){return _vm.cli(history, index)}}},[_vm._v(" "+_vm._s(history.shortname)+" ")])}),0),_c('div',{staticClass:"popupleft-title"},[_vm._v("保险公司")]),_vm._l((_vm.shortName),function(item,index){return (item.data.length > 0)?_c('div',{key:index,staticClass:"titlecontent"},[_c('div',[_vm._v(_vm._s(item.tag))]),_c('div',{on:{"click":function($event){return _vm.change_color(item, index)}}},_vm._l((item.data),function(ite,ind){return _c('div',{key:ind,style:({
              backgroundColor:
                _vm.gb_compory_color == ind && _vm.change_colorr == item.tag
                  ? _vm.getStorage('theme')
                  : '',
              color:
                _vm.gb_compory_color == ind && _vm.change_colorr == item.tag
                  ? 'white'
                  : '',
            }),on:{"click":function($event){return _vm.change_compory_color(ite, ind)}}},[_c('div',[_vm._v(" "+_vm._s(ite.shortname)+" ")])])}),0)]):_vm._e()})],2),_c('div',{staticClass:"bottom-cz"},[_c('div',{staticClass:"bottom-chongz",on:{"click":_vm.reset}},[_c('div',{staticClass:"chongzhi"},[_vm._v("重置")])]),_c('div',{staticClass:"bottom-qued",style:({ backgroundColor: _vm.getStorage('theme') }),on:{"click":_vm.onSelectorChoose}},[_c('div',{staticClass:"queding"},[_vm._v("确定")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }